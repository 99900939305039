@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .sidebar-icon {
        @apply relative flex items-center justify-center
            h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
            bg-secondaryBlue text-primaryBlue
            hover:bg-primaryBlue hover:text-white
            rounded-3xl hover:rounded-xl
            transition-all duration-300 ease-linear
            cursor-pointer;
    }
    .sidebar-tooltip {
        @apply fixed w-auto p-2 m-2 min-w-max left-14
            rounded-md shadow-md
            text-white bg-tertiaryBlue
            text-xs font-bold z-[9999]
            transition-all duration-100 scale-0 origin-left;
    }
    .menubar-link {
        @apply relative flex items-center bg-secondaryBlue text-primaryBlue
            rounded-xl hover:text-white transition-all
            duration-300 ease-linear
            ml-2 mr-2 my-4 px-2;
    }
}

@layer utilities {
  .scrollbar-hide {
    /* For Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* For IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}